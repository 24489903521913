import React from 'react';
import { Box, makeStyles, BoxProps } from '@material-ui/core';

export const CardContainer = (props: BoxProps) => {
	const classes = useStyles({ border: false });
	return (
		<Box className={classes.cardsContainer} display="flex" my={3} flexWrap="wrap" {...props}>
			{props.children}
			<Box className={classes.containerBorders}></Box>
		</Box>
	);
};

export const Card = ({
	title,
	children,
	border = true,
	width,
	color,
	onClick
}: {
	title: string;
	children?: any;
	border?: boolean;
	width?: number | string;
	color?: string;
	onClick?: () => void;
}) => {
	const classes = useStyles({ border });
	return (
		onClick ?
		<Box className={classes.card} flexDirection="column" style={{ width, cursor: "pointer" }} onClick={onClick}>
			<Box className={classes.cardTitle}>{title}</Box>
			<Box display="flex" flex={1} height="100%" justifyContent="center" alignItems="center" color={color} flexDirection={'column'}>
				{children}
			</Box>
		</Box> :
		<Box className={classes.card} flexDirection="column" style={{ width }}>
			<Box className={classes.cardTitle}>{title}</Box>
			<Box display="flex" flex={1} height="100%" justifyContent="center" alignItems="center" color={color} flexDirection={'column'}>
				{children}
			</Box>
		</Box>
	);
};

const useStyles = makeStyles({
	cardsContainer: {
		position: 'relative',
		borderRadius: '3px',
		paddingRight: 2,
		paddingLeft: 2,
	},
	containerBorders: {
		boxSizing: 'border-box',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		border: '10px solid white',
		borderTop: '5px solid white',
		borderBottom: '5px solid white',
		borderRadius: '3px',
		boxShadow: '0px 0px 0px 1px #CCC',
		pointerEvents: 'none',
	},
	card: {
		boxSizing: 'border-box',
		padding: '15px 5px',
		height: 80,
		width: 150,
		border: (props: { border: boolean }) => (props.border ? '1px solid #CCC' : 'none'),
		marginRight: -1,
		marginBottom: -1,
		flexGrow: 1,
		textAlign: 'center',
	},
	cardTitle: {
		textTransform: 'uppercase',
		color: '#444',
		fontSize: 11.5,
	},
});
