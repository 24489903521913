import React, { useState } from 'react';
import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import Title from "../Title";
import routes from '../../api/routes';
import PrimaryButton from '../PrimaryButton';
import SendIcon from '@material-ui/icons/Send';
import useFetch from '../../hooks/useFetch';
import { MissionGoalsDto } from '../../dto/missionGoals';
import { post } from '../../api';
import { MissionUserGoalsDto } from '../../dto/missionUserGoals';
import { AccountCircle } from '@material-ui/icons';
import NumberInput from '../form/NumberInput';

export class UserGoalsInfo {
	id: number;
	name: string;
	avatarPath: string;
	goals?: MissionUserGoalsDto;
	isRe: boolean;
}

const GoalsForm = ({ onClose, missionId }: { onClose: () => any, missionId?: number }) => {
	let [, missionGoals] = useFetch<MissionGoalsDto>(routes.missions.goals.get({ missionId }), []);
	let [, individualGoals] = useFetch<[UserGoalsInfo]>(routes.missions.goals.allRecruiterGoals({ missionId }));
	let [newMissionGoals, setNewMissionGoals] = useState({
		missionId: +missionId,
	} as MissionGoalsDto);
	let [changedGoals, setChangedGoals] = useState({});
	let [done, setDone] = useState(false);

	function submit() {
		submitUserGoals();
		submitMissionGoals()
		setDone(true);
	}

	function submitMissionGoals() {
		if (!newMissionGoals) { return }
		post(routes.missions.goals.createOrUpdate, newMissionGoals);
	}

	function submitUserGoals() {
		for (const goals_user in changedGoals) {
			if (changedGoals[goals_user]) {
				post(routes.missions.goals.createOrUpdateUserGoals,  {
					missionId: missionId,
					recruiterId: goals_user,
					...changedGoals[goals_user]
				},)
			}
		}
		console.log(individualGoals);
	}

	return <Box style={{ width: "min(450px, 80vw)" }}>
		<Title mb={2}>Renseigner les objectifs</Title>
		{done ? <Box>
			<Box mb={2}>
				<Title small color="#44BB44">Les objectifs ont bien été envoyés !</Title>
			</Box>
			<Box mt={1} display="flex" justifyContent="end">
				<PrimaryButton onClick={onClose}>Fermer</PrimaryButton>
			</Box>
		</Box> :
			<Box>
				<Paper variant="outlined" style={{padding: "16px", marginTop: "16px"}}>
					<Title big color="#44BB44">Mission</Title>
					<Box mb={2}>
						<NumberInput fullWidth label="Heures rue (optionnel)" value={newMissionGoals?.streetHours || missionGoals?.streetHours} onChange={(n) => setNewMissionGoals({...newMissionGoals, streetHours: n})}/>
					</Box>
					<Box mb={2}>
						<NumberInput fullWidth label="BS réel (optionnel)" value={newMissionGoals?.completedDonations || missionGoals?.completedDonations} onChange={(n) => setNewMissionGoals({...newMissionGoals, completedDonations: n})}/>
					</Box>
					<Box mb={2}>
						<NumberInput fullWidth label="Don Moyen" value={newMissionGoals?.averageDonation || missionGoals?.averageDonation} onChange={(n) => setNewMissionGoals({...newMissionGoals, averageDonation: n})}/>
					</Box>
					<Box mb={2}>
						<NumberInput fullWidth label="Âge médian" value={newMissionGoals?.medianAge || missionGoals?.medianAge} onChange={(n) => setNewMissionGoals({...newMissionGoals, medianAge: n})}/>
					</Box>
					<Box mb={2}>
						<NumberInput fullWidth label="% Stop PA" value={newMissionGoals?.stopPAPercent || missionGoals?.stopPAPercent} onChange={(n) => setNewMissionGoals({...newMissionGoals, stopPAPercent: n})}/>
					</Box>
					{individualGoals?.map(report => (
						<UserGoals key={report.id} userInfo={report} changedGoals={changedGoals} setChangedGoals={setChangedGoals}/>
					))}
				</Paper>
				<Box mt={1} display="flex" justifyContent="end">
					<Button onClick={() => submit()}>
						<Box mr={1.5} mt={1}> <SendIcon /> </Box>
						Envoyer
					</Button>
				</Box>
			</Box>}
	</Box>
}

const UserGoals = (props: {userInfo: UserGoalsInfo, changedGoals: any, setChangedGoals: any}) => {
	function updateGoals(field, n) {
		let copy = props.changedGoals;
		if (!copy[props.userInfo.id]) {
			copy[props.userInfo.id] = {}
		}
		copy[props.userInfo.id][field] = n;
		props.setChangedGoals(copy);
		console.log(props.changedGoals);
	}

	return (
		<Paper variant="outlined" style={{padding: "16px", marginTop: "16px"}}>
			<Box display={'flex'} flexDirection={'row'} paddingBottom={"8px"} alignItems={'center'}>
				{
					props.userInfo.avatarPath ? <Box
						style={{
							borderRadius: 3,
							height: 50,
							width: 50,
							background: `center / cover no-repeat url(${props.userInfo.avatarPath})`,
						}}
					/> : <AccountCircle  style={{height: 50, width: 50}}/>
				}
				<Box>
					<Typography style={{ fontSize: "32px", fontWeight: 'bold', marginLeft: "8px"}}>{props.userInfo.name}</Typography>
					{ !props.userInfo.isRe || <Title marginLeft={"8px"} marginTop={"-8px"} fontSize={"32px"} fontStyle={'bold'} margin-top={"0px"} margin={"0px"} color="#44BB44">Responsable d'équipe</Title>}	
				</Box>
			</Box>
				{props.userInfo.isRe ? (
				<Box>
					<GoalField userInfo={props.userInfo} changedGoals={props.changedGoals} updateGoals={updateGoals} displayName='HR Rue' fieldName='streetHours'/>
					<GoalField userInfo={props.userInfo} changedGoals={props.changedGoals} updateGoals={updateGoals} displayName='BS Réel' fieldName='completedDonations'/>
					<GoalField userInfo={props.userInfo} changedGoals={props.changedGoals} updateGoals={updateGoals} displayName='Don médian' fieldName='medianDonation'/>
					<GoalField userInfo={props.userInfo} changedGoals={props.changedGoals} updateGoals={updateGoals} displayName='Âge Médian' fieldName='medianAge'/>
					<GoalField userInfo={props.userInfo} changedGoals={props.changedGoals} updateGoals={updateGoals} displayName='%Stop PA' fieldName='stopPAPercent'/>
				</Box>
				) : (
				<Box>
					<GoalField userInfo={props.userInfo} changedGoals={props.changedGoals} updateGoals={updateGoals} displayName='Taux Réel' fieldName='realRate'/>
					<GoalField userInfo={props.userInfo} changedGoals={props.changedGoals} updateGoals={updateGoals} displayName='Don Médian' fieldName='medianDonation'/>
					<GoalField userInfo={props.userInfo} changedGoals={props.changedGoals} updateGoals={updateGoals} displayName='Âge Médian' fieldName='medianAge'/>
					<GoalField userInfo={props.userInfo} changedGoals={props.changedGoals} updateGoals={updateGoals} displayName='%18-30' fieldName='percentage1830'/>
					<GoalField userInfo={props.userInfo} changedGoals={props.changedGoals} updateGoals={updateGoals} displayName='%Stop PA' fieldName='stopPAPercent'/>
				</Box>
			)}
		</Paper>
	);
}

const GoalField = (props: {userInfo: UserGoalsInfo, changedGoals: any, updateGoals: any, displayName: string, fieldName: string}) => {
	return (<TextField style={{marginBottom: "16px"}} inputProps={{ type: 'number'}} label={props.displayName + ": " + (props.userInfo?.goals[props.fieldName] || "")} value={props.changedGoals[props.userInfo?.goals[props.fieldName]]} onChange={(n) =>{props.updateGoals(props.fieldName, +n.target.value)}} variant='outlined' fullWidth/>
	)
}
export default GoalsForm;