import React from 'react';
import { MissionDailyReportOnRecruiterDto } from '../../dto/missionReport';
import useFetch from '../../hooks/useFetch';
import routes from '../../api/routes';
import { Box } from '@material-ui/core';

export default function CommentDailyReport({
	filterDate,
	userId,
	missionId,
}: {
	filterDate: string;
	userId: string | undefined;
	missionId: string;
}) {
	const [, dailyReport] = useFetch<MissionDailyReportOnRecruiterDto>(
		userId
			? routes.missions.reports.recruiterDailyReport.get(
					{ id: missionId },
					{ recruiterId: userId, date: filterDate },
			  )
			: routes.missions.reports.dailyReport({ id: missionId }, { date: filterDate }),
		userId ? [userId, filterDate, missionId] : [filterDate],
	);

	return <Box paddingX={"16px"} paddingBottom={"16px"} paddingTop={"-8px"}> {dailyReport?.comment || 'Pas de commentaire.'}</Box>;
}
