import { Box, Breadcrumbs, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import AssociationSelect from '../../components/associations/AssocationSelect';
import DropDown from '../../components/DropDown';
import DatePicker from '../../components/form/DatePicker';
import MissionsTable from '../../components/missions/MissionsTable';
import Title from '../../components/Title';
import { getYearsSinceFoundation } from '../../utils';

const Missions = () => {
	const [associationId, setAssociationId] = useState<number>();
	const [periodStart, setPeriodStart] = useState(null);
	const [periodEnd, setPeriodEnd] = useState(null);
	const [missionType, setMissionType] = useState(null);
	const [yearFilter, setYearFilter] = useState('2024');
	const { url } = useRouteMatch();

	const dropdownType = {};
	dropdownType['Tout sélectionner'] = 'Tout sélectionner';
	dropdownType['Paris'] = 'paris';
	dropdownType['Fixe'] = 'fixe';
	dropdownType['IT'] = 'it';
	dropdownType['CCO'] = 'cco';

	return (
		<Box px={3} py={3}>
			{/* Header */}
			<Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
				<Breadcrumbs aria-label="breadcrumb">
					<Typography color="textPrimary">Missions</Typography>
				</Breadcrumbs>
				<Box>
					<Link to={`${url}/creation`}>
						<Button size="small" variant="contained" color="primary">
							<Add /> Créer
						</Button>
					</Link>
				</Box>
			</Box>

			{/* Filters */}
			<Box py={2} margin="-8px">
				<Box display="flex" width="100%" alignItems="center">
					<DropDown
						values={['Tout sélectionner', 'Paris', 'Fixe', 'IT', 'CCO']}
						onChange={(e) => setMissionType(dropdownType[e])}
						margin={8}
						width="50%"
					/>
					<AssociationSelect
						value={associationId}
						onChange={(value) => setAssociationId(value)}
						label="Association"
						required
						width={'50%'}
						flexGrow={1}
						margin={8}
						variant="outlined"
					/>
				</Box>
				<Box display="flex" width="100%" alignItems="center">
					<DatePicker
						format="DD/MM/YYYY"
						label="Début de la période"
						width={'50%'}
						flexGrow={1}
						margin={8}
						value={periodStart}
						onChange={(date) => {
							setPeriodStart(date);
						}}
					/>
					<DatePicker
						format="DD/MM/YYYY"
						label="Fin de la période"
						width="50%"
						flexGrow={1}
						margin={8}
						value={periodEnd}
						onChange={(date) => {
							setPeriodEnd(date);
						}}
					/>
					<DropDown
						values={["Toutes", ...getYearsSinceFoundation().map(year => year.toString()), "Toutes"]}
						label='Année'
						onChange={(e) => setYearFilter(e === "Toutes" ? null : e)}
						margin={8}
						value={yearFilter}
						width={"100px"}
					/>
				</Box>
			</Box>

			{/* Table */}
			<Box display="flex" flexWrap="wrap" m={-0.8} width={"30%"} alignItems={'center'}>
				<Title>Liste des missions</Title>
			</Box>
			<MissionsTable
				periodStart={periodStart}
				periodEnd={periodEnd}
				associationId={associationId}
				clearPeriod={() => {
					setPeriodStart(null);
					setPeriodEnd(null);
				}}
				yearFilter={yearFilter}
				missionType={missionType}
			/>
		</Box>
	);
};

export const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	excelButton: {
		padding: '8px 8px',
		minWidth: 10,
		marginRight: 5,
	},
}));

export default Missions;
