import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	IconButton,
	InputBase,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Pagination } from '@material-ui/lab';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import React, { useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { post } from '../../api';
import routes from '../../api/routes';
import AssociationSelect from '../../components/associations/AssocationSelect';
import DonationDetails from '../../components/donations/DonationDetails';
import Select from '../../components/form/Select';
import MissionSelect from '../../components/missions/MissionSelect';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import UserSelect, { UserSuggestion } from '../../components/user/UserSelect';
import {
	DonationDisplayedStatusLabels,
	DonationFilterStatusLabels,
	DonationListRowDto,
	DonationsAllowExporLabels,
	DonationsListFiltersDto,
	rowsPerPage,
} from '../../dto/donation';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import TextInput from '../../components/form/TextInput';
import { getYearsSinceFoundation } from '../../utils';
import DropDown from '../../components/DropDown';

const serialize = (data) => encodeURIComponent(JSON.stringify(data));

const Donations = () => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));

	const [reloadToken, setReloadToken] = useState(0);
	const [page, setPage] = useState(1);

	const form = useForm({
		init: new DonationsListFiltersDto(),
	});
	const [recruiter, setRecruiter] = useState<UserSuggestion>(null);
	const [searchValue, setSearchValue] = useState('');
	const [inputSearch, setInputSearch] = useState('');
	const [yearFilter, setYearFilter] = useState('2024');

	let filters = form.values;
	filters.page = page;
	filters.recruiterId = recruiter?.id;
	filters.year = +yearFilter;

	const [sort, setSort] = useState<{}>({ datetime: 'DESC' });

	const [, currentCount] = useFetch<{ count: number }>(
		routes.donations.count({}, { filters: serialize({ ...filters, searchValue: inputSearch }) }),
		[
			filters.recruiterId,
			filters.donationStatus,
			filters.associationId,
			filters.missionIds,
			filters.displayedStatus,
			filters.allowExport,
			filters.year,
			reloadToken,
			searchValue,
		],
	);

	const [, pendingDonations] = useFetch<{ count: number }>(
		routes.donations.count(
			{},
			{ filters: serialize({ ...filters, donationStatus: 'complete', allowExport: 'not_allowed' }) },
		),
		[filters.recruiterId, filters.associationId, filters.missionIds, filters.displayedStatus, filters.year, reloadToken],
	);

	const [isLoading, donations, , setDonations] = useFetch<DonationListRowDto[]>(
		routes.donations.all({}, { filters: serialize({ ...filters, searchValue: inputSearch, sort }) }),
		[
			page,
			filters.recruiterId,
			filters.donationStatus,
			filters.associationId,
			filters.missionIds,
			filters.allowExport,
			filters.displayedStatus,
			filters.year,
			reloadToken,
			JSON.stringify(sort),
			searchValue,
		],
	);

	console.log('filters', filters);

	const [openDonationId, setOpenDonationId] = useState(null);
	const [openGroupedValidation, setOpenGroupedValidation] = useState<boolean>(false);
	const [groupedValidationContent, setGroupedValidationContent] = useState<string>('');
	const [openGroupedCancellation, setOpenGroupedCancellation] = useState<boolean>(false);
	const [groupedCancellationContent, setGroupedCancellationContent] = useState<string>('');

	const classes = useStyles({ border: false });
	const { user } = useAuth();

	const columns = [
		{ label: 'RUM', field: 'rum' },
		{ label: 'Date', field: 'datetime' },
		{ label: 'Mission', field: 'tricoCode' },
		{ label: 'ONG', field: 'associationName' },
		{ label: 'Recruteur', field: 'recruiterFirstName' },
		{ label: 'Montant', field: 'monthlyDonation' },
		{ label: 'Statut', field: 'displayedStatus' },
		{ label: 'Nom', field: 'lastName' },
		{ label: 'Prénom', field: 'firstName' },
		{ label: 'Nb Appels', field: 'callCount' },
		{ label: '' },
	];

	const sortBy = (field: string) => () => {
		if (sort[field] === 'DESC') {
			setSort({ [field]: 'ASC' });
		} else if (sort[field] === 'ASC') {
			setSort({});
		} else {
			setSort({ [field]: 'DESC' });
		}
	};

	const setAllowExport = (id: number, allowExport: boolean) => {
		return post(routes.donations.allowExport({}), { values: [{ id, allowExport }] }).then();
	};

	return (
		<Box p={3}>
			<Box mb={2} display="flex" justifyContent="space-between">
				<Title>Liste des bulletins</Title>
				<Box>
					<Button
						className={classes.excelButton}
						size="small"
						variant="contained"
						color="primary"
						onClick={() => setOpenGroupedValidation(true)}
					>
						Validation Groupée
					</Button>
					<Button
						className={classes.excelButton}
						size="small"
						variant="contained"
						color="secondary"
						onClick={() => setOpenGroupedCancellation(true)}
					>
						Annulation Groupée
					</Button>
					<Link
						href={routes.donations.excel(
							{ clientId: user && user.client ? user.client.id : '0' },
							{ filters: serialize({ ...filters, searchValue: inputSearch }) },
						)}
					>
						<Button className={classes.excelButton} size="small" variant="contained" color="primary">
							<FaFileExcel size={16} />
						</Button>
					</Link>
				</Box>
			</Box>

			{openDonationId && (
				<Modal open onClose={() => setOpenDonationId(null)}>
					<DonationDetails
						reload={() => setReloadToken(reloadToken + 1)}
						exit={() => setOpenDonationId(null)}
						expandedSection="personnal_information"
						donationId={openDonationId}
					/>
				</Modal>
			)}
			{openGroupedValidation && (
				<Modal open onClose={() => setOpenGroupedValidation(false)}>
					<div style={{ display: 'block' }}>
						<TextInput
							label="Copier coller les rum à valider dans cette case"
							width={400}
							onChange={(value) => setGroupedValidationContent(value)}
							value={groupedValidationContent}
							marginBottom={16}
							rows={10}
							multiline={true}
						/>
						<div style={{ marginBottom: 10 }}>
							<Button
								variant="contained"
								color="primary"
								onClick={async () => {
									const rums = groupedValidationContent
										.split('\n')
										.filter((rum) => rum.length > 0)
										.map((rum) => rum.trim());
									const ret = await post(routes.donations.calls.groupedValidation({}), {
										rums,
									});
									if (ret.changedRows > 0) {
										setGroupedValidationContent('');
										setReloadToken(reloadToken + 1);
										setOpenGroupedValidation(false);
									}
								}}
							>
								Valider
							</Button>
						</div>
						<Title>Liste des Rums détectées :</Title>
						<ul>
							{groupedValidationContent
								.split('\n')
								.filter((rum) => rum.length > 0)
								.map((rum) => rum.trim())
								.map((rum) => (
									<li>{rum}</li>
								))}
						</ul>
					</div>
				</Modal>
			)}
			{openGroupedCancellation && (
				<Modal open onClose={() => setOpenGroupedCancellation(false)}>
					<div style={{ display: 'block' }}>
						<TextInput
							label="Copier coller les rum à annuler dans cette case"
							width={400}
							onChange={(value) => setGroupedCancellationContent(value)}
							value={groupedCancellationContent}
							marginBottom={16}
							rows={10}
							multiline={true}
						/>
						<div style={{ marginBottom: 10 }}>
							<Button
								variant="contained"
								color="primary"
								onClick={async () => {
									const rums = groupedCancellationContent
										.split('\n')
										.filter((rum) => rum.length > 0)
										.map((rum) => rum.trim());
									const ret = await post(routes.donations.calls.groupedCancellation({}), {
										rums,
									});
									if (ret.changedRows > 0) {
										setGroupedCancellationContent('');
										setReloadToken(reloadToken + 1);
										setOpenGroupedCancellation(false);
									}
								}}
							>
								Annuler
							</Button>
						</div>
						<Title>Liste des Rums détectées :</Title>
						<ul>
							{groupedCancellationContent
								.split('\n')
								.filter((rum) => rum.length > 0)
								.map((rum) => rum.trim())
								.map((rum) => (
									<li>{rum}</li>
								))}
						</ul>
					</div>
				</Modal>
			)}
			<Box width="100%" display="flex" marginBottom={'16px'} alignItems={'center'}>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						setSearchValue(inputSearch);
					}}
					style={{ display: 'flex', width: '100%' }}
				>
					<Paper
						style={{ display: 'flex', padding: '2px 4px', width: '100%', border: '1px solid #CCC' }}
						variant="outlined"
					>
						<InputBase
							placeholder="Recherche"
							style={{ padding: '8px 10px', width: '100%' }}
							value={inputSearch}
							onChange={(e) => setInputSearch(e.target.value)}
						/>
						<IconButton type="submit">
							<SearchIcon />
						</IconButton>
					</Paper>
				</form>
			</Box>

			<Box display="flex" flexWrap="wrap" m={-0.8}>
				<AssociationSelect {...form.field('associationId')} margin={8} />
				<MissionSelect
					associationId={filters.associationId}
					multiple
					{...form.field('missionIds')}
					margin={8}
				/>
				<Select
					label="Statut"
					values={{ '': 'Tous les status', ...DonationFilterStatusLabels }}
					{...form.field('donationStatus')}
					margin={8}
				/>
				<Select
					label="Statut export"
					values={{ '': 'Tous les status', ...DonationDisplayedStatusLabels }}
					{...form.field('displayedStatus')}
					margin={8}
				/>
				<Box style={{ margin: 8 }} flexGrow={1} flexShrink={1}>
					<UserSelect value={recruiter} onChange={setRecruiter} />
				</Box>
				<Select
					label="Export"
					values={DonationsAllowExporLabels}
					{...form.field('allowExport')}
					margin={8}
				/>
				<DropDown
					values={["Toutes", ...getYearsSinceFoundation().map(year => year.toString()), "Toutes"]}
					label='Année'
					onChange={(e) => setYearFilter(e === "Toutes" ? null : e)}
					margin={8}
					value={yearFilter}
					width={"100px"}
				/>
			</Box>

			<Box
				my={2}
				p={2}
				style={{
					border: '1px solid #CCC',
					borderRadius: '3px',
				}}
			>
				Il y a <b>{currentCount?.count || 0}</b> bulletins correspondant à vos filtres. Il y a{' '}
				<b>{pendingDonations?.count || 0}</b> bulletins complets en attente d'autorisation d'export
			</Box>

			<Box
				my={2}
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 346px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
					marginTop: 15,
				}}
			>
				<Table size="medium" classes={{ root: classes.tableRoot }}>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell style={{ cursor: 'pointer' }}>
									<Box
										display="flex"
										flexDirection="row"
										justifyContent="space-between"
										alignItems="center"
										onClick={sortBy(column.field)}
									>
										<Box>{column.label}</Box>
										{column.field ? (
											sort[column.field] && (
												<IconButton size="small" style={{ marginLeft: 10 }}>
													<ArrowDownwardIcon
														style={{
															fontSize: 14,
															transition: 'transform 0.3s',
															transform:
																sort[column.field] === 'ASC'
																	? 'rotate(180deg)'
																	: 'rotate(0deg)',
														}}
														fontSize="small"
													/>
												</IconButton>
											)
										) : (
											<span>Prêt</span>
										)}
									</Box>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					{!isLoading && (
						<TableBody>
							{donations?.map((row) => (
								<TableRow
									key={row.id}
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setOpenDonationId(row.id);
									}}
								>
									<TableCell>{row.rum}</TableCell>
									<TableCell>
										{row.datetime ? moment(row.datetime).format('DD/MM/YYYY') : ''}
									</TableCell>
									<TableCell>{row.tricoCode}</TableCell>
									<TableCell>{row.associationName}</TableCell>
									<TableCell>
										{row.recruiterFirstName} {row.recruiterLastName}
									</TableCell>
									<TableCell>{row.monthlyDonation}€</TableCell>
									<TableCell>{DonationDisplayedStatusLabels[row.displayedStatus]}</TableCell>
									<TableCell>{row.lastName}</TableCell>
									<TableCell>{row.firstName}</TableCell>
									<TableCell>{parseInt((row.callCount as any) || '0')}</TableCell>
									<TableCell>
										<Checkbox
											size="small"
											checked={!!row.allowExport}
											onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
												setAllowExport(row.id, !row.allowExport).then(() => {
													row.allowExport = !row.allowExport;
													setDonations([...donations]);
												});
											}}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					)}
				</Table>

				{isLoading && (
					<Box display="flex" width="100%" justifyContent="center" my={8}>
						<CircularProgress />
					</Box>
				)}

				<Box p={1} display="flex" justifyContent="end">
					<Pagination
						page={page}
						onChange={(_, page) => {
							setPage(page);
						}}
						count={Math.ceil((currentCount?.count || 0) / rowsPerPage)}
					/>
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	tabs: {
		marginBottom: -1,
		'& button': {
			borderColor: '#CCC',
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0,
			fontSize: 12,
			padding: '10px 15px',
		},
	},
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody tr.noBottomBorder > td': {
			borderBottom: 'none',
		},
		'& tbody tr:not(.comment):hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
	excelButton: {
		padding: '8px 8px',
		minWidth: 10,
		marginRight: 5,
	},
}));

export default Donations;
