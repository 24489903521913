import React from 'react';
import routes from '../../api/routes';
import { AssociationFiltersDto } from '../../dto/association';
import { AssociationStats, calculateStats } from '../../dto/associationUtils';
import useFetch from '../../hooks/useFetch';
import { UserDto } from '../../dto/user';
import moment from 'moment';
import { formatNumber } from '../../utils';
import { Card } from '../Cards';

const Dashboard2 = ({
	filters,
	user,
	hideDetails,
}: {
	filters: AssociationFiltersDto;
	user: UserDto;
	hideDetails?: boolean;
}) => {
	const [, dayPartialDashboardStats] = useFetch<AssociationStats>(
		routes.associations.dashboardStats({}, filters),
		[JSON.stringify(filters)],
	);

	let dashboardStats = calculateStats((dayPartialDashboardStats || {}) as any);

	return (
		<>
			{!hideDetails && (
				<>
					<Card title="BS rue">{formatNumber(dashboardStats.donationsTotalCount, 2)}</Card>
					<Card title="Tx rue">{formatNumber(dashboardStats.streetRate, 2)}</Card>
					<Card title="%Transfo">{dashboardStats.transformationRate}%</Card>
					<Card title="%H/F/X">{formatNumber(dashboardStats.malePercent, 2) + "/" + formatNumber(dashboardStats.femalePercent, 2) + "/" + formatNumber(dashboardStats.otherGenderPourcent, 2)}</Card>
					<Card title="Âge moyen">{formatNumber(dashboardStats.averageAge, 2)}</Card>
					<Card title="Montant collecté">{formatNumber(dashboardStats.donationsTotalAmout, 2)}</Card>
				</>
			)}

			{filters.recruiterId &&
				filters.periodStart !== '' &&
				moment(filters.periodStart).format('YYYY-MM-DD') ===
					moment(filters.periodEnd).format('YYYY-MM-DD') && (
					<Card title="Exposé">
						{dashboardStats.exposeType
							? `${dashboardStats.exposeType} : ${dashboardStats.exposeName}`
							: "Pas d'exposé"}
					</Card>
				)}
		</>
	);
};

export default Dashboard2;
