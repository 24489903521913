import { Box, Paper } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import Title from '../Title';
import CommentDailyReport from './CommentDailyReport';
import DashboardDaily from './MissionDashboardDaily';
import { AssociationFiltersDto } from '../../dto/association';
import PrimaryButton from '../PrimaryButton';
import { MissionDto } from '../../dto/mission';
import { UserDto } from '../../dto/user';

const MissionDatas = ({ mission, user, userId, filters }: { mission: MissionDto, user: UserDto, userId: string, filters: AssociationFiltersDto }) => {
	const show = (userId && user && mission) || (!userId && mission);

	let start = (filters && moment(filters.periodStart) > moment(mission.startDate)) ? moment(filters.periodStart) : moment(mission.startDate);
	let end = (filters && moment(filters.periodEnd) < moment(mission.endDate)) ? moment(filters.periodEnd) : moment(mission.endDate);
	const days = getMissionDays(mission.rhythm, start, end).reverse();

	return (
		<Box style={{overflowY: 'scroll'}} maxHeight={"400px"} width={"100%"} marginRight={"0px"}>
			{show &&
				days.map((day, i) => {
					return <MissionData key={`${day.format('YYYY-MM-DD')}-datas`} mission={mission} userId={userId} day={day}/>
				})}
		</Box>
	);
};

const MissionData = ({ mission, userId, day }) => {
	const filters = {
		...new AssociationFiltersDto(),
		periodStart: moment(day).format('YYYY-MM-DD 00:00:00'),
		periodEnd: moment(day).format('YYYY-MM-DD 23:59:59'),
	};
	const [showDetails, setShowDetails] = useState(false)

	return (
		<Paper style={{ width:"100%", paddingBottom: "2px", marginBottom: "8px"}}>
			<Box paddingTop={"8px"} paddingBottom={"-8px"} display="flex" alignItems={'center'} textAlign={'center'} mb={2}>
				<Title marginLeft={'16px'}>
					Données du {moment(day).format('DD/MM/YYYY')}
				</Title>
				<PrimaryButton mini style={{height: "24px", maxWidth: "32px", width: "32px"}} onClick={() => setShowDetails(!showDetails)}>
					{showDetails ? '-' : '+'}
				</PrimaryButton>
			</Box>
				<DashboardDaily mission={mission} day={day} filters={filters} hideDetails={!showDetails} />
				{ showDetails && 
					<CommentDailyReport
						filterDate={moment(day).format('YYYY-MM-DD')}
						userId={userId}
						missionId={mission.id}
					/>
				}
		</Paper>
	)
};

export default MissionDatas;

const getMissionDays = (rhythm: string, startDate: moment.Moment, endDate: moment.Moment) => {
	let current = moment(startDate);
	const missionDays: moment.Moment[] = [];
	while (current <= endDate) {
		// rythm == ms (mardi to samedi) => we skip sunday (0) and monday (1)
		// rythm == lv (lundi to vendredi) => we skip saturday(6) and sunday(0)
		if (
			(rhythm === 'ms' && current.day() !== 0 && current.day() !== 1) ||
			(rhythm === 'lv' && current.day() !== 0 && current.day() !== 6)
		) {
			missionDays.push(moment(current));
		}
		current = current.add(1, 'day');
	}
	return missionDays;
};
