import removeAccents from 'remove-accents';

export function formatNumber(number: number | string, nbOfDecimals: number = 0): string {
	const factor = 10 ** nbOfDecimals;
	const parsedNumber = typeof number === 'number' ? number : parseFloat(number);
	return String(Math.round((parsedNumber || 0) * factor) / factor).replace('.', ',');
}

export default function normalizeString(string) {
	return removeAccents(string).toLowerCase();
}

export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export function getYearsSinceFoundationAsObject(): { [key: string]: number } {
	let years = getYearsSinceFoundation();
	let yearsObject = {};

	for (const year of years) {
		yearsObject[year.toString()] = year;
	}
	return yearsObject;
}

export function getYearsSinceFoundation(): number[] {
	let years = [];
	let currentYear = new Date().getFullYear();
	let start = 2020;
	while (currentYear >= start) {
		years.push(currentYear);
		currentYear -= 1;
	}
	return years;
}