import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import routes from '../../api/routes';
import DeleteButton from '../../components/form/DeleteButton';
import MissionForm from '../../components/missions/MissionForm';
import { MissionDto } from '../../dto/mission';
import { MissionReportsDto } from '../../dto/missionReport';
import useFetch from '../../hooks/useFetch';

const EditMission = () => {
	const { id } = useParams<{ id: string }>();
	const [isLoading, mission] = useFetch<MissionDto>(routes.missions.get({ id: +id }));
	const [isLoading2, reports] = useFetch<MissionReportsDto>(routes.missions.reports.get({ id }), []);
	const url = routes.missions.edit({ id: +id });

	const init = { ...mission };
	delete (init as any)['association'];
	delete (init as any)['programManager'];
	delete (init as any)['teamManager'];

	return (
		<Box px={4} py={0} position="relative" display="flex" flexDirection="column" alignItems="center" mb={8}>
			{isLoading || isLoading2 ? (
				<Box my={10}>
					<CircularProgress />
				</Box>
			) : (
				<MissionForm edit init={init} url={url} reports={reports}>
					<Box py={1} mr={1}>
						<DeleteButton
							url={routes.missions.delete({ id: +id })}
							confirmationMessage="Êtes vous sûr de vouloir supprimer la mission ?"
							successMessage="La mission a bien été supprimée !"
							returnLabel="Retour aux missions"
							returnUrl={`/missions`}
						>
							Supprimer la mission
						</DeleteButton>
					</Box>
				</MissionForm>
			)}
		</Box>
	);
};

export default EditMission;
