import React, { useState } from 'react';
import { Box, Button, Paper, Typography } from "@material-ui/core";
import Title from "../Title";
import routes from '../../api/routes';
import PrimaryButton from '../PrimaryButton';
import SendIcon from '@material-ui/icons/Send';
import TextInput from '../form/TextInput';
import { MissionReportsDto, MissionReportsOnRecruiterDto } from '../../dto/missionReport';
import useFetch from '../../hooks/useFetch';
import moment from 'moment';
import useAuth from '../../hooks/useAuth';
import { AccountCircle } from '@material-ui/icons';
import { post } from '../../api';

export class UserReportInfo {
	id: number;
	name: string;
	avatarPath: string;
	reports: MissionReportsOnRecruiterDto;
}


const ReportForm = ({ onClose, missionId }: { onClose: () => any, missionId: string }) => {
	let [, missionReports] = useFetch<MissionReportsDto>(routes.missions.reports.get({ id: missionId }), []);
	let { user } = useAuth();
	let [midForm, setMidForm] = useState('');
	let [endForm, setEndForm] = useState('');
	let [, individualReports] = useFetch<[UserReportInfo]>(routes.missions.reports.allRecruiterReports({ id: missionId }), []);
	let [done, setDone] = useState(false);
	let [changedReports, setChangedReports] = useState({});

	function submit() {
		submitMidCommentary()
		submitEndCommentary()
		submitUserCommentaries()
		setDone(true);
	}

	function submitMidCommentary() {
		if (!midForm) { return }
		post(routes.missions.reports.createOrUpdate,  {
			id: missionReports?.mid?.id,
			type: 'mid',
			missionId: missionId,
			date: moment().format('YYYY-MM-DD'),
			comment: midForm,
			creatorId: user.id,
		},)
	}

	function submitEndCommentary() {
		if (!endForm) { return }
		post(routes.missions.reports.createOrUpdate,  {
			id: missionReports?.end?.id,
			type: 'end',
			missionId: missionId,
			date: moment().format('YYYY-MM-DD'),
			comment: endForm,
			creatorId: user.id,
		},)
	}

	function submitUserCommentaries() {
		for (const report_user in changedReports) {
			if (changedReports[report_user].mid) {
				post(routes.missions.reports.createOrUpdateOnRecruiter,  {
					type: 'mid',
					missionId: missionId,
					date: moment().format('YYYY-MM-DD'),
					comment: changedReports[report_user].mid,
					creatorId: user.id,
					recruiterId: report_user
				},)
			}
			if (changedReports[report_user].end) {
				post(routes.missions.reports.createOrUpdateOnRecruiter,  {
					type: 'end',
					missionId: missionId,
					date: moment().format('YYYY-MM-DD'),
					comment: changedReports[report_user].end,
					creatorId: user.id,
					recruiterId: report_user
				},)
			}
		}
	}

	return <Box style={{ width: "min(450px, 80vw)" }}>
		{done ? <Box>
			<Box mb={2}>
				<Title small color="#44BB44">Les commentaires ont bien été envoyés !</Title>
			</Box>
			<Box mt={1} display="flex" justifyContent="end">
				<PrimaryButton onClick={onClose}>Fermer</PrimaryButton>
			</Box>
		</Box> :
		<Box>
			<Title mb={2}>Commentaire de mi-mission</Title>
			<TextInput fullWidth multiline rows={5}  label={missionReports?.mid ? "Commentaire actuel: '" + missionReports.mid.comment + "'" : 'Pas de commentaire'} value={midForm} onChange={(str) => setMidForm(str)}/>
			<Title mb={2}>Commentaire de fin de mission</Title>
			<TextInput fullWidth multiline rows={5}  label={missionReports?.end ? "Commentaire actuel: '" + missionReports.end.comment + "'" : 'Pas de commentaire'} value={endForm} onChange={(str) => setEndForm(str)}/>
			{individualReports?.map(report => (
				<UserReportForm key={report.id} userInfo={report} changedReports={changedReports} setChangedReports={setChangedReports}/>
			))}
			<Box mt={1} display="flex" justifyContent="end">
				<Button onClick={() => submit()}>
					<Box mr={1.5} mt={1}> <SendIcon /> </Box>
					Envoyer
				</Button>
			</Box>
		</Box>}
	</Box>
}

const UserReportForm = (props: {userInfo: UserReportInfo, changedReports: any, setChangedReports: any}) => {
	let [, setMidComment] = useState('');
	let [, setEndComment] = useState('');

	function updateMidComment(str) {
		setMidComment(str);
		let copy = props.changedReports;
		if (!copy[props.userInfo.id]) {
			copy[props.userInfo.id] = {}
		}
		copy[props.userInfo.id].mid = str;
		props.setChangedReports(copy);
	}

	function updateEndComment(str) {
		setEndComment(str);
		let copy = props.changedReports;
		if (!copy[props.userInfo.id]) {
			copy[props.userInfo.id] = {}
		}
		copy[props.userInfo.id].end = str;
		props.setChangedReports(copy);
	}

	return (
		<Paper variant="outlined" style={{padding: "16px", marginTop: "16px"}}>
			<Box display={'flex'} flexDirection={'row'} paddingBottom={"8px"}>
				{
					props.userInfo.avatarPath ? <Box
						style={{
							borderRadius: 3,
							height: 50,
							width: 50,
							background: `center / cover no-repeat url(${props.userInfo.avatarPath})`,
						}}
					/> : <AccountCircle  style={{height: 50, width: 50}}/>
				}
				<Typography style={{ fontSize: "32px", fontWeight: 'bold', marginLeft: "8px"}}>{props.userInfo.name}</Typography>	
			</Box>
			<Title mb={2}>Commentaire de mi-mission</Title>
			<TextInput fullWidth multiline rows={5}  label={props.userInfo.reports?.mid?.comment ? "Commentaire actuel: '" + props.userInfo.reports.mid.comment + "'" : 'Pas de commentaire'} onChange={updateMidComment}/>
			<Title mb={2}>Commentaire de fin de mission</Title>
			<TextInput fullWidth multiline rows={5}  label={props.userInfo.reports?.end?.comment ? "Commentaire actuel: '" + props.userInfo.reports.end.comment + "'" : 'Pas de commentaire'} onChange={updateEndComment}/>
		</Paper>
	);
  }

export default ReportForm;