import { Box, Button, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Chart as ChartJs, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import routes from '../../api/routes';
import useFetch from '../../hooks/useFetch';
import Title from '../Title';

ChartJs.register(...registerables, ChartDataLabels);

type DataPoint = {
	title: string;
	periodType: 'week' | 'day';
	period: string;
	donationsCount: number;
	averageDonation: number;
	realRate: number;
	averageAge: number;
	medianAge: number;
};

export const MissionOverviewChart = (props: { missionId: string; missionTricoCode?: string; userId?: string }) => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const [periodDataPoint, setPeriodDataPoint] = useState<DataPoint | null>(null);
	const [, data1] = useFetch<DataPoint[]>(
		routes.missions.overviewStats({ id: props.missionId }, { userId: props.userId }),
		[props.userId],
	);
	const [, data2] = useFetch<DataPoint[]>(
		routes.missions.overviewStats(
			{ id: props.missionId },
			{
				userId: props.userId,
				periodType: periodDataPoint ? 'day' : 'week',
				periodStart: periodDataPoint ? periodDataPoint.period : '',
			},
		),
		[periodDataPoint, props.userId],
	);
	return (
		<Paper
			variant="outlined"
			style={{
				position: 'relative',
				padding: 20,
				marginBottom: 20,
			}}
		>
			<Box textAlign="center" py={1}>
				<Title style={{ textAlign: 'center' }}>
					{periodDataPoint
						? 'Détail de la semaine ' + periodDataPoint.title
						: `VUE D'ENSEMBLE DE LA MISSION${props.missionTricoCode ? " " + props.missionTricoCode : ""}`}
				</Title>
				{!!periodDataPoint && (
					<Button
						style={{ position: 'absolute', top: 20, left: 10 }}
						onClick={() => setTimeout(() => setPeriodDataPoint(null), 500)}
					>
						<ArrowBack />
					</Button>
				)}
			</Box>
			<Box display="flex" justifyContent="center" py={2}>
				<Box
					style={{
						position: 'relative',
						width: isBigScreen ? 'calc(100vw - 410px)' : '100%',
						maxWidth: '1000px',
					}}
				>
					<OveriewChart
						data={(periodDataPoint && data2 ? data2 : data1) || []}
						onClick={(dataPoint) => {
							if (!periodDataPoint) {
								setPeriodDataPoint(dataPoint);
							}
						}}
					/>
				</Box>
			</Box>
		</Paper>
	);
};

const OveriewChart = (props: { data: DataPoint[]; onClick: (element: DataPoint, index: number) => void }) => {
	const data = tricoDataToChartJsData(props.data);

	const minRealRate = Math.min(...props.data.map((d) => d.realRate));
	const maxRealRate = Math.max(...props.data.map((d) => d.realRate));
	const realRateGap = Math.max(0.2, maxRealRate - minRealRate);

	const minAge = Math.min(...props.data.map((d) => d.averageAge));
	const maxAge = Math.max(...props.data.map((d) => d.averageAge));
	const ageGap = Math.max(2, maxAge - minAge);

	const minDonation = Math.min(...props.data.map((d) => d.averageDonation));
	const maxDonation = Math.max(...props.data.map((d) => d.averageDonation));
	const donationGap = Math.max(0.2, maxDonation - minDonation);

	return (
		<Chart
			type="bar"
			data={data as any}
			options={{
				onClick: (_ev, elements) => {
					if (elements.length > 0) {
						const element = elements[0];
						props.onClick(props.data[element.index], element.index);
					}
				},
				// aspectRatio: 1.5,
				scales: {
					x: {
						display: true,
					},
					yAverageAge: {
						display: false,
						max: minAge + ageGap * 1.5,
						min: minAge - ageGap * 3,
					},
					yMedianAge: {
						display: false,
						max: minAge + ageGap * 1.5,
						min: minAge - ageGap * 3,
					},
					yAverageDonation: {
						display: false,
						max: minDonation + donationGap * 2.6,
						min: minDonation - donationGap * 1.5,
					},
					yRealRate: {
						display: false,
						max: minRealRate + realRateGap * 3.8,
						min: minRealRate - realRateGap * 0.3,
					},
					yDonationCount: {
						display: false,
					},
				},
				plugins: {
					legend: {
						display: false,
						// This fix a bug, do not remove
						// https://stackoverflow.com/questions/68359625/chart-js-console-js-error-while-destroy-the-chart-on-click-event
						events: [],
					} as any,
				},
			}}
		/>
	);
};

const tricoDataToChartJsData = (dataPoints: DataPoint[]) => {
	return {
		labels: dataPoints.map((e) => e.title),
		datasets: [
			{
				type: 'line' as const,
				label: 'Donation moyenne',
				borderColor: '#495796',
				borderWidth: 2,
				fill: false,
				data: dataPoints.map((e) => e.averageDonation),
				yAxisID: 'yAverageDonation',
				datalabels: {
					anchor: 'end',
					align: 'end',
					offset: -4,
					color: '#495796',
					font: {
						size: 16,
					},
				},
			},
			{
				type: 'line' as const,
				label: 'Age moyen',
				borderColor: '#c64646',
				borderWidth: 2,
				fill: false,
				data: dataPoints.map((e) => e.averageAge),
				yAxisID: 'yAverageAge',
				datalabels: {
					anchor: 'end',
					align: 'end',
					offset: -4,
					color: '#c64646',
					font: {
						size: 16,
					},
				},
			},
			{
				type: 'line' as const,
				label: 'Age médian',
				borderColor: '#ffa500',
				borderWidth: 2,
				fill: false,
				data: dataPoints.map((e) => e.medianAge),
				yAxisID: 'yMedianAge',
				datalabels: {
					anchor: 'end',
					align: 'end',
					offset: -4,
					color: '#ffa500',
					font: {
						size: 16,
					},
				},
			},
			{
				type: 'line' as const,
				label: 'Taux réel',
				borderColor: '#429a9b',
				borderWidth: 2,
				fill: false,
				data: dataPoints.map((e) => e.realRate),
				yAxisID: 'yRealRate',
				datalabels: {
					anchor: 'end',
					align: 'end',
					offset: -4,
					color: '#429a9b',
					font: {
						size: 16,
					},
				},
			},
			{
				type: 'bar' as const,
				label: 'Nombre de dons',
				backgroundColor: '#b7de6b',
				data: dataPoints.map((e) => e.donationsCount),
				borderColor: 'white',
				borderWidth: 2,
				yAxisID: 'yDonationCount',
				datalabels: {
					anchor: 'start',
					align: 'end',
					color: '#444',
					font: {
						size: 16,
						weight: 'bold',
					},
				},
			},
		],
	};
};
