import { GradeDto } from './grade';
import { Nationality } from './nationalities';
import { AddressDto } from './address';
import { ClientDto } from './client';
import { Role } from './role';
import { FileDto } from './file';
import { MissionDto } from './mission';
import { MissionExpensesDto } from './missionExpenses';

export const rowsPerPage = 20;

export const Titles = {
	'': '',
	m: 'Monsieur',
	mme: 'Madame',
};
export type Title = keyof typeof Titles;

export const MutuelleOptions = {
	'': '',
	Opt1: 'Opt1',
	Opt2: 'Opt2',
};

export type MutuelleOption = keyof typeof MutuelleOptions;

export const Contracts = {
	'': '',
	CDD: 'CDD',
	CDI: 'CDI',
	stage: 'Stage',
	appr: 'Apprentissage',
};

export type Contract = keyof typeof Contracts;

export const Genders = {
	'': '',
	male: 'Masculin',
	female: 'Féminin',
};
export type Gender = keyof typeof Genders;

export const ClothingSizes = {
	'': '',
	xs: 'XS',
	s: 'S',
	m: 'M',
	l: 'L',
	xl: 'XL',
	'2xl': '2XL',
	'3xl': '3XL',
};
export type ClothingSize = keyof typeof ClothingSizes;

export const States = {
	Actif: 'Actif',
	Inactif: 'Inactif',
	NG: 'NG',
};
export type State = keyof typeof States;

export const Grades = {
	'': '',
	re1: 'RE1',
	bureau: 'Bureau',
	rdg1: 'RDG1',
	rdg2: 'RDG2',
};
export type Grade = keyof typeof Grades;

// You need to have the same roles/category/categoryOptions in grades and user tables
export const Categories = {
	ETAM: 'ETAM',
	IC: 'IC',
};

export type Category = keyof typeof Categories;

// You need to have the same roles/category/categoryOptions in grades and user tables
export const ETAMCategoryOptions = {
	'1.3.1': '1.3.1',
	'1.3.2': '1.3.2',
	'1.4.1': '1.4.1',
	'1.4.2': '1.4.2',
	'2.1': '2.1',
	'2.2': '2.2',
	'2.3': '2.3',
	'3.1': '3.1',
	'3.2': '3.2',
	'3.3': '3.3',
};
export type ETAMCategoryOption = keyof typeof ETAMCategoryOptions;

// You need to have the same roles/category/categoryOptions in grades and user tables
export const ICCategoryOptions = {
	'1.1': '1.1',
	'1.2': '1.2',
	'2.1': '2.1',
	'2.2': '2.2',
	'2.3': '2.3',
	'3.1': '3.1',
	'3.2': '3.2',
	'3.3': '3.3',
};

export const CategoryOptionsList = Array.from(
	new Set([...Object.keys(ICCategoryOptions), ...Object.keys(ETAMCategoryOptions)]),
);

export type ICCategoryOption = keyof typeof ICCategoryOptions;

export const PositionsInfos = {
	RD: { label: 'Recruteur de Donateurs', hourlyRate: 0 },
	RE: { label: "Responsable d'Equipe", hourlyRate: 0 },
	REFE: { label: "Responsable d'Equipe. de Formations et d'Entretiens", hourlyRate: 16.0 },
	SPR: { label: 'Superviseur de Pré-recrutement de Rue', hourlyRate: 13.0 },
	PRR: { label: 'Pré-Recruteur de Rue', hourlyRate: 11.0 },
	AP: { label: 'Assistante Polyvalente', hourlyRate: 10.1 },
	AGQ: { label: 'Assistante Gestion et Qualité', hourlyRate: 11.0 },
	CPR: { label: 'Chargé(e) de Pré-Recrutement', hourlyRate: 11.0 },
	CL: { label: 'Chargé(e) de Logistique', hourlyRate: 11.0 },
	CG: { label: 'Chargé(e) de Gestion', hourlyRate: 11.0 },
	MPD: { label: 'Médiateur Pétition Débutant', hourlyRate: 10.1 },
	MPC: { label: 'Médiateur Pétition Confirmé', hourlyRate: 11.0 },
	MPA: { label: 'Médiateur Pétition Autonome', hourlyRate: 12.0 },
	REPD: { label: "Responsable d'Equipe Pétition Débutant", hourlyRate: 14.0 },
	REPC: { label: "Responsable d'Equipe Pétition Confirmé", hourlyRate: 14.5 },
	CD: { label: 'Chargé(e) de Développement', hourlyRate: 14.0 },
	RRH: { label: 'Responsable des Ressources Humaines', hourlyRate: 15.0 },
	RDev: { label: 'Responsable du Développement', hourlyRate: 18.0 },
	DGI: { label: "Directeur Général et de l'Innovation", hourlyRate: 18.0 },
	RO: { label: 'Responsable des Opérations', hourlyRate: 16.0 },
	MT: { label: 'Médiateur(rice) Téléphonique', hourlyRate: 10.1 },
	APR: { label: 'Assistant(e) de Pré-Recrutement', hourlyRate: 10.1 },
	S: { label: 'Stagiaire', hourlyRate: 3.6 },
	AL: { label: 'Assistant(e) de Logistique', hourlyRate: 10.1 },
	RRD: { label: 'Responsable de la Recherche et du Développement', hourlyRate: 15.0 },
	ROT: { label: 'Responsable Opérationnel Terrain', hourlyRate: 16.0 },
	AD: { label: 'Assistant(e) de Direction', hourlyRate: 13.0 },
	GE: { label: 'Gérant(e)', hourlyRate: 14.0 },
};

export const Positions = Object.assign(
	{},
	...Object.entries(PositionsInfos).map(([value, { label }]) => ({ [value]: label })),
);

export type Position = keyof typeof PositionsInfos;

export enum endContractType {
	EMPLOYEE,
	EMPLOYER,
}

export enum ApplicantSourceType {
	CAREERS = 'CAREERS',
	INDEED = 'INDEED',
	WEBSITE = 'WEBSITE',
	SPONSORSHIP = 'SPONSORSHIP',
	PRR = 'PRR',
	SECTEUR = 'SECTEUR',
	OTHER = 'OTHER',
}

export const ApplicantSourceTypeDisplay: Record<ApplicantSourceType, string> = {
	CAREERS: 'Page Carrière',
	INDEED: 'Indeed',
	WEBSITE: 'Site internet',
	SPONSORSHIP: 'Parrainage',
	PRR: 'PRR',
	SECTEUR: 'Secteur',
	OTHER: 'Autres annonces',
};

export class UserDto {
	constructor() {
		this.address = new AddressDto();
	}

	id: number;
	title: Title = '';
	gender: Gender = '';
	clothingSize: ClothingSize = '';
	state: State;
	tricoId: string;
	hourlyRate = 0;
	birthdate = '';
	nationalityPassport: Nationality;
	nationality: Nationality;
	residencePermit = '';
	residencePermetExpiryDate = '';
	residencePermitQuotation: string;
	residencePermitInstitution: string;
	birthCity = '';
	birthZipCode = '';
	email = '';
	emailPerso = '';
	isDriver = false;
	drivingCertificate = false;
	drivingLicenseDate = '';
	firstName = '';
	lastName = '';
	password = '';
	address: AddressDto;
	client: ClientDto;
	phoneNumber = '';
	emergencyContactRelationType = '';
	emergencyContactLastName = '';
	emergencyContactFirstName = '';
	emergencyContactPhoneNumber = '';
	emergencyContactPhoneNumber2 = '';
	contractStartDate = '';
	contractEndDate = '';
	trialPeriodEndType: string = null;
	trialPeriodEndDate: string = null;
	noticePeriodDate: string = null;
	contract: Contract = '' as any;
	contractWeeklyHours = 0;
	contractWeekDays = '';
	/** rib is actually IBAN */
	rib = '';
	bic = '';
	swileNumber = '';
	role: Role = 'donor_recruiter' as any;
	gradeId: string = null;
	grade?: GradeDto;
	contractEndType?: string;
	pictureRights = false;
	rgpd = false;
	mutuelle = false;
	mutuelleOption: MutuelleOption = '';
	avatar: FileDto;
	position: Position = 'RD';
	category: Category;
	categoryOption: ETAMCategoryOption | ICCategoryOption;
	comment: string;
	isDefaultPasswordReset?: boolean;
	thirdMissionComment: string;
	socialSecurity: string;
	applicantSource?: ApplicantSourceType;
	applicantSourceOther?: string;
	sponsorId?: number;
	sponsor?: UserDto;
	sponsees?: UserDto[];
	prevoyance?: boolean;
	rqth?: boolean;
	goodies?: string;
	updateHRInfosDate?: string;
	isApplicant?: boolean;
	hasAccessToBODashboard?: boolean;
	hasAccessToBODonations?: boolean;
	hasAccessToBOLogistic?: boolean;
	hasAccessToBOMissions?: boolean;
	hasAccessToBOHR?: boolean;
	hasAccessToBOInterviews?: boolean;
	missionExpenses?: MissionExpensesDto[];
	files?: FileDto[];
	expoPushToken?: string;
	changedGradeDuringMissionDate?: string;
}

export type UserAccesses = Pick<
	UserDto,
	| 'hasAccessToBODashboard'
	| 'hasAccessToBODonations'
	| 'hasAccessToBOLogistic'
	| 'hasAccessToBOMissions'
	| 'hasAccessToBOHR'
	| 'hasAccessToBOInterviews'
>;

export class CreateUserDto extends UserDto {}

export class HumanResourcesRowDto extends UserDto {
	totalLate: number;
	totalAbsent: number;
	avatarPath: string;
	nbMissions: number;
	currentMissionTricoCode: string;
}

export class UserStatsDto {
	totalLate: number;
	totalAbsent: number;
	paidHours: Array<{ year: number; totalPaidHours: number }>;
	missions: MissionDto[];
}

export class MissionUserDto {
	id: string;
	firstName: string;
	lastName: string;
	avatarPath: string;
	position: string;
	trCount: string;
	absence: number;
	streetHoursTotal: number;
	paidHoursTotal: number;
	usedTrCount: number;
	completedDonationsCount: number;
	transformationRate: number;
	realRate: number;
	realRateOver25: number;
	averageStopPA: number;
	averageDonation: number;
	between18And20Count: number;
	completedDonationsOver25Count: number;
	tricoId: string;
	grade: string;
	clothingSize: string;
	tabletId: string;
	medianAge?: number;
	contractEndDate: string;
	trialPeriodEndType: string;
	trialPeriodEndDate: string;
	noticePeriodDate: string;
	gender?: string;
}
