import React, { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useFetch from '../../hooks/useFetch';
import routes from '../../api/routes';
import { Box } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { Position } from '../../dto/user';
import { useEffect } from 'react';

export type UserSuggestion = {
	id: string;
	firstName: string;
	lastName: string;
	path: string;
};

type UserSelectProps = {
	value?: UserSuggestion;
	onChange?: (user: UserSuggestion) => any;
	positions?: Position[];
	placeholder?: string;
	actifOnly?: boolean;
};

export default function UserSelect({
	value,
	onChange,
	positions,
	placeholder = 'Tricoteur',
	actifOnly = false,
}: UserSelectProps) {
	const [search, setSearch] = useState('');
	const suggestions = useRef<Record<string, UserSuggestion>>({});
	useFetch<UserSuggestion[]>(
		routes.users.suggestions({}, { search, positions: JSON.stringify(positions || []), actifOnly }),
		[search, positions],
		(users) => {
			suggestions.current = {};
			users.forEach((user) => (suggestions.current[user.id] = user));
		},
	);

	useEffect(() => setSearch(''), [positions]);

	return (
		<Autocomplete
			value={value}
			getOptionSelected={(option, value) => option.id === value.id}
			getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
			options={Object.values(suggestions.current)}
			onInputChange={(event, value) => setSearch(value)}
			onChange={(e: any, value: UserSuggestion) => onChange?.(value)}
			renderOption={(option) => (
				<React.Fragment>
					<Box display="flex" alignItems="center">
						<Box mr={1}>
							{option.path ? (
								<Box
									style={{
										borderRadius: 3,
										height: 25,
										width: 25,
										background: `center / cover no-repeat url(${option.path})`,
									}}
								/>
							) : (
								<AccountCircle />
							)}
						</Box>
						<Box>
							{option.firstName} {option.lastName}
						</Box>
					</Box>
				</React.Fragment>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					label={placeholder}
					variant="outlined"
					InputProps={{
						...params.InputProps,
						endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
					}}
				/>
			)}
		/>
	);
}
