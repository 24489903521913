import { MissionDto } from './mission';
import { Gender, Title, UserDto } from './user';

export class DonationFiltersDto {
	missionId?: string;
	recruiterId?: string;
	runningMissionsOnly?: boolean;
	associationId?: number;
	periodStart?: string;
	periodEnd?: string;
}

export const rowsPerPage = 20;

export const DonationsAllowExporLabels = {
	both: 'Tout',
	allowed: 'Autorisé',
	not_allowed: 'Pas autorisé',
};
export class DonationsListFiltersDto {
	status: 'current' | 'past';
	page: number;
	associationId: number;
	missionIds: string[] = [];
	donationStatus: DonationFilterStatus;
	displayedStatus?: DonationDisplayedStatus;
	year?: number;
	recruiterId: string;
	clientId?: number | null;
	sort: Record<string, string>;
	allowExport?: keyof typeof DonationsAllowExporLabels;
	searchValue: string;
	donationGrade: string;
	callLeft: string;
	welcomeCallLeft: string;
}

export class TricoteursFiltersDto {
	gradeId: string;
	state: string;
	isWorking: boolean;
	infoHR: string;
	page: number;
}

export const DonationFilterStatusLabels = {
	complete_cancelled: 'Annulé en attrition',
	incomplete_cancelled: 'Annulé en incomplet',
	complete: 'Complet',
	incomplete: 'Incomplet',
};
export type DonationFilterStatus = keyof typeof DonationFilterStatusLabels;

export const DonationStatusLabels = {
	cancelled: 'Annulé',
	complete: 'Complet',
	incomplete: 'Incomplet',
};
export type DonationStatus = keyof typeof DonationStatusLabels;

export const DonationDisplayedStatusLabels = {
	transmitted: 'Transmis',
	error: 'Erreur',
	to_validate: 'À valider',
	meeting: 'Rendez-vous',
};
export type DonationDisplayedStatus = keyof typeof DonationDisplayedStatusLabels;

export const Grades = {
	C7: 'C7',
	C8: 'C8',
	C9: 'C9',
	C10: 'C10',
	U1: 'U1',
	U2: 'U2',
	U3: 'U3',
	U4: 'U4',
	U5: 'U5',
	U6: 'U6',
	U8: 'U8',
	I1: 'I1',
	DU: 'DU',
};

export const GradesColor = {
	C7: '#8417FF',
	C8: '#8F8D89',
	C9: '#48C750',
	C10: '#FA4E2A',
	U1: '#56EB5E',
	U2: '#FA9201',
	U3: '#DEC20D',
	I: '#8F654C',
	I1: '#0E2DC7',
	DU: '#000000',
};

export class DonationGradesDto {
	grade: string;
}

export class DonationDto {
	id: number;
	rum: string;
	recruiterId: string = '';
	recruiter?: UserDto;
	missionId: number;
	mission?: MissionDto;
	title: Title = '';
	gender: Gender = '';
	email: string = '';
	firstName: string = '';
	lastName: string = '';
	birthdate: string = '';
	phoneNumber: string = '';
	commQualitative?: boolean | null;
	commEmail?: boolean;
	commSms?: boolean;
	commMail?: boolean;
	commPhone?: boolean;
	advertisement?: boolean;
	subscribe?: boolean;
	receivesNewsletters: boolean = false;
	receivesMagazine: boolean = false;
	receivesReceiptByEmail: boolean = false;
	receivesReceiptByLetter: boolean = false;
	monthlyDonation: string = '';
	donationDay: number;
	/** rib is actually IBAN */
	rib: string = '';
	bic: string = '';
	bankName?: string;
	signature: string = '';
	datetime: string = '';
	status: DonationStatus;
	displayedStatus: DonationDisplayedStatus;
	meetingDatetime: string;
	street: string = '';
	streetNumber: string = '';
	addressDetails: string = '';
	country: string = '';
	city: string = '';
	zipCode: string = '';
	signatureLocation: string = '';
	grade: DonationGradesDto;
	donationGrade: string = '';
	exportDatetime?: string;
	allowExport?: boolean;
}

export class DonationPersonalDetailsDto {
	title: Title = '';
	gender: Gender = '';
	email: string = '';
	firstName: string = '';
	lastName: string = '';
	birthdate: string = '';
	phoneNumber: string = '';
	street: string = '';
	streetNumber: string = '';
	addressDetails: string = '';
	city: string = '';
	zipCode: string = '';
}
export class DonationListRowDto extends DonationDto {
	associationName: string;
	recruiterFirstName: string;
	recruiterLastName: string;
	addressDetails: string;
	callCount: number;
	welcomeCallCount: number;
	tricoCode: string;
	recruiterTricoCode: string;
	allowExport: boolean;
}

export const DonorCallStatusLabels = {
	unknown: 'Pas de status',
	no_response: 'Pas de réponse',
	cancelled: 'Annulé',
	responded: 'A répondu',
};

export type DonorCallStatus = keyof typeof DonorCallStatusLabels;

export class DonorCallDto {
	id: number;
	callDatetime: string;
	status: DonorCallStatus;
	comment: string;
	donationId: number;
	recruiterId: string;
	callDuration: string;
}

export class DonationBankingDetailsDto {
	/** rib is actually IBAN */
	rib: string = '';
	bic: string = '';
}

export class DonationAmountDto {
	monthlyDonation: string = '';
}

export class DonationStatusDto {
	status: DonationStatus = 'complete';
}

export class DonorCallListRowDto {
	firstName: string;
	lastName: string;
	associationName: string;
	recruiterFirstName: string;
	recruiterLastName: string;
	addressDetails: string;
	city: string;
	zipCode: string;
	callCount: number;
	donationId: number;
	tricoCode: string;
	callDatetime: string;
}
